.project {
    background: var(--color-project);
    display: flex;
    flex-direction: row;
    gap: 5rem;
    padding: 3rem;
    border-radius: $border-radius;
    box-shadow: $shadow;
    margin-top: 5rem;

    @include respond(phone-port) {
        flex-direction: column;
    }

    &__img {
        max-width: 50rem;
        box-shadow: $shadow;
        align-self: center;
        transform-origin: left;
        transition: all .2s;
        cursor: zoom-in;

        @include respond(tab-land) {
            max-width: 40rem;
        }
        @include respond(tab-port) {
            max-width: 35rem;
        }
        @include respond(phone-port) {
            max-width: 100%;
        }

        &:hover {
            opacity: .8;
        }

        &-popup {
            position: fixed;
            max-width: 80%;
            left: 50%;
            top: 50%;
            z-index: 4;
            pointer-events: none;
            transform: translate(-50%, -50%);

            &--active {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }

        &-popup-screen {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, .2);
            transform: scale(0);
            backdrop-filter: blur(10px);
            z-index: 100;
            transition: transform .2s;

            &--active {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & button {
            width: auto;
        }

        & div {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-end;
            row-gap: 2rem;
        }
    }

    &__icons {
        color: var(--color-link);
        display: flex;
        gap: 2rem;

        & svg {
            height: 5rem;
            width: 5rem;
        }
    }
}

.project-view {

    &__video {
        width: 100%;
        margin: 4rem 0;
    }

    &__img {
        max-width: 100%;
        margin: 4rem 0;
    }
}