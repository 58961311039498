#personSvg {
    height: 15rem;
    width: auto;
}
.speach #personSvg {
    @include respond(tab-port) {
        height: 10rem;
        margin-left: 5rem;
    }
    @include respond(phone) {
        height: 10rem;
        margin-left: 0;
    }
}
#personSvgCircle {
    color: var(--color-speach2);
}

.speach {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8rem;
    margin-top: 5rem;
    filter: drop-shadow($shadow);

    @include respond(tab-port) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        margin-top: -6rem;
        gap: 5rem;
    }
    

    &__content {
        background: var(--color-speach);
        padding: 2.5rem;
        border-radius: $border-radius;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            background-color: var(--color-speach2);
            height: 5rem;
            width: 6rem;
            left: -5.9rem;
            top: 3rem;
            clip-path: polygon(0 90%, 100% 0, 100% 100%);
            @include respond(tab-port) {
                height: 4rem;
                width: 4rem;
                top: -3.5rem;
                transform: rotate(90deg);
                left: 10rem;
            }
            @include respond(phone) {
                height: 4rem;
                width: 4rem;
                top: -3.5rem;
                transform: rotate(90deg);
                left: 5rem;
            }
        }
    }

}

.skills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem 5rem;
    margin: 5rem 0;

    @include respond(tab-port) {
        flex-direction: column;
    }
    
    &__container {
        background: var(--color-skill);
        padding: 2rem;
        border-radius: $border-radius;
        text-align: center;
        border-top: 1rem solid var(--color-tertiary);
        box-shadow: $shadow;

        @include respond(tab-port) {
            border-top: none;
            border-left: 1rem solid var(--color-tertiary);
        }

        &--red {
            border-top: 1rem solid var(--color-secondary);

            @include respond(tab-port) {
                border-top: none;
                border-left: 1rem solid var(--color-secondary);
            }
        }

        & h2 {
            font-weight: 500;
            color: var(--color-text-variant);
            margin-bottom: 2rem;
        }
    }
}

.logos {
    display: flex;
    flex-flow: row wrap;
    color: var(--color-logos);
    justify-content: space-between;
    gap: 2rem 2rem;

    @include respond(phone) {
        justify-content: center;
    }

    & svg {
        height: 8rem;
    }
}

.project-collection {
    margin: 5rem 0;
    padding: 3rem;
    background: var(--color-project);
    border-radius: $border-radius;
    box-shadow: $shadow;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    gap: 3rem;
    position: relative;

    @include respond(tab-land) {
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    }
    @include respond(tab-port) {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
    @include respond(phone) {
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    @include respond(smallest) {
        max-width: 90vw;
    }

    &__img {
        position: relative;
        overflow: hidden;
        box-shadow: $shadow;

        & img {
            width: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            // background-color: red;
            left: 0;
            top: 0;
            background-image: linear-gradient(var(--color-primary), var(--color-primary-dark)), url('../img/projects/ArsContainer.png');
            background-size: cover;
            background-blend-mode: color;
        }

        &--1::after { background-image: linear-gradient(var(--color-primary), var(--color-primary-dark)), url('../img/projects/ArsContainer.png'); }
        &--2::after { background-image: linear-gradient(var(--color-primary), var(--color-primary-dark)), url('../img/projects/TheoStoetzer.png'); }
        &--3::after { background-image: linear-gradient(var(--color-primary), var(--color-primary-dark)), url('../img/projects/Handballplanner.png'); }
        &--4::after { background-image: linear-gradient(var(--color-primary), var(--color-primary-dark)), url('../img/projects/D2C2.png'); }

        &--1:hover::after { background-image: url('../img/projects/ArsContainer.png'); }
        &--2:hover::after { background-image: url('../img/projects/TheoStoetzer.png'); }
        &--3:hover::after { background-image: url('../img/projects/Handballplanner.png'); }
        &--4:hover::after { background-image: url('../img/projects/D2C2.png'); }
    }
}