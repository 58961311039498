.resume {
    background: var(--color-resume);
    padding: 3rem 4rem;
    border-radius: $border-radius;
    box-shadow: $shadow;
    margin-top: 5rem;

    @include respond(phone) {
        padding: 2rem;
    }

    & h2 {
        margin-bottom: 3rem;
        grid-column: -2 / -1;
        text-transform: uppercase;
        font-weight: 400;
    }

    & h3 {
        margin-bottom: .8rem;
    }

    &__container {
        display: grid;
        grid-template-columns: auto auto 1fr;
        column-gap: 2rem;

        @include respond(phone-port) {
            grid-template-columns:  auto 1fr;
        }
    }
    
    &__year {
        color: var(--color-primary-light);

        @include respond(phone-port) {
            display: none;
        }
        
    }

    &__year--inside {
        display: none;
        color: var(--color-primary-light);

        @include respond(phone-port) {
            display: block;
        }
    }

    &__item {
        position: relative;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            height: 105%;
            width: 1rem;
            background-color: var(--color-primary-light);
            top: 0;
            left: -3.7rem;
            border-radius: .5rem .5rem 0 0;
        }

        & ul {
            list-style-position: inside;
        }
        & *:last-child {
            margin-bottom: 4rem;
        }
        & p:not(:last-child) {
            margin-bottom: 0;
        }
    }

    &__dot {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: var(--color-primary-light);
        position: relative;
    }

}