.header {
    background: var(--color-header);
    padding-top: 5rem;
    display: grid;
    grid-template-columns: [full-start] 1fr $content-padding [content-start] minmax(auto, $content-width) [content-end] $content-padding 1fr [full-end];
    justify-items: center;

    @include respond(phone-port) {
        grid-template-columns: [full-start content-start] minmax(auto, $content-width) [content-end full-end];
        padding-top: 1rem;
    }

    & * {
        grid-column: content;
        
    }
}

#headerImage {
    height: auto;
    width: 1000px !important;
    max-width: 100%;
}
#headerImage.header-image--small {
    width: 550px !important;
}
#headerImage.header-image--contact {
    width: 600px !important;
}

#headerImage * {
    shape-rendering: geometricPrecision;
    font-family: "Montserrat", sans-serif;
}


#screenButton {
    cursor: pointer;
}
#screenButton * {
    transition: all .2s;
}
#screenButton:hover *:not(:first-child) {
    transform: translate(-2rem, -2rem);
}
#screenButton:hover *:first-child {
    transform: translate(1rem, 1rem);
}
#screenButton:active *:not(:first-child) {
    transform: translate(-1rem, -1rem);
}
#screenButton:active *:first-child {
    transform: translate(.5rem, .5rem);
}
#screenButton:hover *:nth-child(2) {
    fill: #3f216d;
}

#eyeRight, #eyeLeft { transform-origin: center;transform-box: fill-box; animation: blink 5s ease-in-out infinite; }

// #shine { animation: flicker 10s linear 2s infinite; }
.dark .shine-animation { animation: flicker1 .2s linear .2s forwards; }
.light .shine-animation { animation: flicker1 .2s linear .2s backwards; }
.shine-hidden {opacity: 0 !important;}

#iconCss { animation: float 2s ease-in-out infinite; }
#iconHtml { animation: float 2s ease-in-out -.5s infinite; }
#iconJs { animation: float 2s ease-in-out -1s infinite; }
#iconReact { animation: float 2s ease-in-out -1.5s infinite; }
#iconSass { animation: float 2s ease-in-out -1.5s infinite; }

#iconDiscord, #iconCodepen, #iconGitHub, #iconLinkedIn {
    color: var(--color-primary-lightest);
    cursor: pointer;

}
#iconDiscord:hover, #iconCodepen:hover, #iconGitHub:hover, #iconLinkedIn:hover {
    color: var(--color-primary);
}

#textPosition1Fill {
    transform-box: fill-box;
    transform-origin: right;
    transform: scaleX(1);
    animation: typing1 2s steps(19, end) forwards;
}
#textPosition2Fill {
    transform-box: fill-box;
    transform-origin: right;
    transform: scaleX(1);
    animation: typing1 1.3s 2.4s steps(12, end) forwards;
}
#textPosition1Cursor {
    transform-box: fill-box;
    animation: typing2 2s steps(19, end) forwards,
        disappear .2s 2s forwards;
}
#textPosition2Cursor {
    transform-box: fill-box;
    animation: appear .2s 2.2s forwards,
        typing3 1.3s 2.4s steps(12, end) forwards,
        typing4 .8s 3.7s linear infinite;
}

#certificate {
    transform-origin: top;
    transform: rotate(-20deg);
    animation: dangle .8s linear forwards;
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(100px); }
    100% { transform: translateY(0px); }
}
@keyframes blink {
    0% { transform: scaleY(1); }
    5% { transform: scaleY(.1); }
    10% { transform: scaleY(1); }
    100% { transform: scaleY(1); }
}

@keyframes flicker1 {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes flicker2 {
    0% { opacity: 1; }
    25% { opacity: 0; }
    50% { opacity: 1; }
    75% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes typing1 {
    from {transform: scaleX(1)}
    to {transform: scaleX(0)}
}
@keyframes typing2 {
    from {transform: translateX(0)}
    to {transform: translateX(5800px)}
}
@keyframes typing3 {
    from {transform: translateX(0)}
    to {transform: translateX(3600px)}
}
@keyframes typing4 {
    0% {opacity: 1;}
    45% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 0;}
}
@keyframes disappear {
    from {opacity: 1;}
    to {opacity: 0;}
}
@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes dangle {
    0% {transform: rotate(-20deg);}
    5% {transform: rotate(-20deg);}
    40% {transform: rotate(15deg);}
    70% {transform: rotate(-12deg);}
    90% {transform: rotate(8deg);}
    100% {transform: rotate(0deg);}
}