// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our nomal styles apply
1800px + :      Big desktop
*/

/*
- phone
- tab-port
- tab-land
- big-desktop

1em = 16py
*/
@mixin respond($breakpoint) {
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };       //1200px
    }
    @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) { @content };        //900px
    }
    @if $breakpoint == phone-port {
        @media only screen and (max-width: 50em) { @content };        //800px
    }
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };     //600px
    }
    @if $breakpoint == smallest {
        @media only screen and (max-width: 25em) { @content };     //400px
    }
}

/*
use the mixins in the following way:
    @include respond(phone) {
        ...
    }
*/