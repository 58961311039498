.navigation {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-self: flex-end;
    margin-bottom: 5rem;

    @include respond(phone-port) {
        margin: 2rem 2rem;
    }

    & ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-size: $fs-medium;

        @include respond(tab-port) {
            flex-flow: wrap;
        }
        @include respond(phone-port) {
            font-size: $fs-regular;
            gap: 1rem;
        }
    }

    &__link {
        color: var(--color-text);
        text-decoration: none;
        font-weight: 500;

        &:hover {
            color: var(--color-text-hover);
        }

        &--active {
            border-bottom: 2px solid currentColor;
        }
    }
}

.themes {
    & fieldset {
        border: none;
        background-color: var(--color-theme-picker);
        position: relative;
        // height: 3rem;
        // width: 6rem;
        // border-radius: 1.5rem;
        padding: .5rem .5rem;
        height: 3rem;
        border-radius: 1.5rem;
        display: flex;
        gap: 1rem;
    }

    &__toggle {
        & input {
            position: absolute;
            opacity: 0;
            z-index: 2;
            pointer-events: none;
        }
    }

    &__radio {
        & svg {
            height: 2rem;
            width: 2rem;
            color: var(--color-theme-picker-text);
            cursor: pointer;
        }
    }

    &__indicator {
        position: absolute;
        height: 3rem;
        width: 3rem;
        background-color: var(--color-theme-picker-indicator);
        border-radius: 50%;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        transform: translateX(0);
        transition: transform .5s;
    }

    & * {
        line-height: 100%;
    }

}
.themes:has(#dark:checked) .themes__indicator {
    transform: translateX(3rem);
}