// .publication {
//     display: block;
//     display: grid;
//     grid-template-columns: 40rem auto;
//     background: var(--color-publication);
//     border-radius: $border-radius;
//     color: var(--color-text);
//     text-decoration: none;
//     overflow: hidden;
//     box-shadow: $shadow;

//     &:not(:last-child) {
//         margin-bottom: 5rem;
//     }

//     &:hover {
//         transition: all .2s;
//         transform: scale(1.02);
//         box-shadow: $shadow-big;
//     }

//     &__image {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         box-shadow: .5rem 0 0 var(--color-shadow);
//     }

//     &__text {
//         padding: 2rem 3rem;
//         display: grid;
//         grid-template-columns: auto 1fr;
//         column-gap: 2rem;

//         & h2 {
//             margin-bottom: 3rem;
//             grid-column: 1 / 3;
//         }
//     }
// }

.publication {
    background: var(--color-project);
    display: flex;
    flex-direction: row;
    gap: 5rem;
    padding: 3rem;
    border-radius: $border-radius;
    box-shadow: $shadow;
    margin-top: 5rem;

    @include respond(phone-port) {
        flex-direction: column;
    }

    &__img {
        max-width: 50rem;
        // box-shadow: $shadow;
        align-self: center;
        transform-origin: left;
        transition: all .2s;
        border: .8rem solid var(--color-shadow);

        @include respond(tab-land) {
            max-width: 40rem;
        }
        @include respond(tab-port) {
            max-width: 35rem;
        }
        @include respond(phone-port) {
            max-width: 100%;
        }
    }

    &__text {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 2rem;

        & h2 {
            margin-bottom: 3rem;
            grid-column: 1 / 3;
            font-size: $fs-medium;
        }

        & a {
            grid-column: 2 / 3;
            justify-self: end;
        }
    }
}