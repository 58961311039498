.footer {
    background-color: var(--color-footer-bg);
    padding: 5rem 0;
    display: grid;
    grid-template-columns: [full-start] 1fr $content-padding [content-start] minmax(auto, $content-width) [content-end] $content-padding 1fr [full-end];
}

.footer__content {
    grid-column: content;
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
        flex-direction: column;
    }

    & a {
        color: inherit;
        text-decoration: none;
        margin-bottom: 1.5rem;

        &:hover {
            color: var(--color-text-hover);
        }
    }
}

#openConsentBtn {
    cursor: pointer;

    &:hover {
        color: var(--color-text-hover);
    }
}