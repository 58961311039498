*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 60%;
    scroll-behavior: smooth;
    @include respond(tab-port) {
        font-size: 50%;
    }
}

body {
    box-sizing: border-box;
    background-color: var(--color-bg);
    color: var(--color-text);
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

::selection {
    background: var(--color-selection-bg);
    color: var(--color-selection-text);
}
::-moz-selection {
    background: var(--color-selection-bg);
    color: var(--color-selection-text);
}

main {
    display: grid;
    grid-template-columns: [full-start] 1fr $content-padding [content-start] minmax(auto, $content-width) [content-end] $content-padding 1fr [full-end];

    @include respond(phone) {
        grid-template-columns: [full-start] 1fr $content-padding-small [content-start] minmax(auto, $content-width) [content-end] $content-padding-small 1fr [full-end];
    }
}
main section {
    grid-column: content;
    margin-top: 8rem;

    &:last-child {
        margin-bottom: 8rem;
    }
}

.hidden {
    display: none !important;
}

input[type='radio'], input[type='checkbox'] {
    accent-color: var(--color-primary);
}

.checkbox-with-heading {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2rem;

    & p {
        grid-column: 2 / 3;
    }
}

input[type='text'], input[type='number'], input[type='tel'], input[type='email'], textarea {
    background: var(--color-input);
    border: none;
    padding: 1.2rem 2rem;
    outline: transparent;
    font-family: inherit;
    font-size: $fs-regular;
    font-weight: 500;
    color: var(--color-input-text);
    border-radius: $border-radius-small;
    box-shadow: $shadow;
    transition: all .2s;

    &::placeholder {
        color: var(--color-placeholder);
    }
    &:focus {
        transform: scale(1.05);
        box-shadow: $shadow-big;
    }
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--color-input-text);
    -webkit-box-shadow: 0 0 0px 1000px var(--color-input) inset;

}

textarea {
    resize: vertical;
}

