.contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem 6rem;
    margin-top: 5rem;
    max-width: 100%;

    & input[type='text'], input[type='email'] {
        @include respond(phone) {
            grid-column: 1 / -1;
        }
    }

    &__message {
        grid-column: 1 / 3;
    }
    &__btn {
        grid-column: 2 / 3;
        justify-self: end;
        width: 20rem;
    }
}