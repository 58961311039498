::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--color-scrollbar-track);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-thumb);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--color-scrollbar-thumb-hover);
}

// @supports (scrollbar-color: auto) {
//     * {
//       scrollbar-color: aquamarine cornflowerblue;
//     }
//   }
  
//   /* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
//   @supports selector(::-webkit-scrollbar) {
//     *::-webkit-scrollbar {
//       background: red;
//       padding: 0;
//     }
//     *::-webkit-scrollbar-thumb {
//       background: blue;
//       margin: 0;
//     }
//   }