.card {
    background: var(--color-card);
    color: var(--color-card-text);
    box-shadow: 1rem 1rem 0px var(--color-shadow);
    border-radius: $border-radius;
    // width: auto;
    // display: inline-block;
    // align-self: center;
    // justify-self: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5rem;
    padding: 4rem;

    @include respond(phone) {
        padding: 3rem;
    }
    @include respond(smallest) {
        padding: 2rem;
    }

    &__section {
        display: grid;
        place-items: center;
    }

    &__img {
        @include respond(phone) {
            display: none;
        }
    }

    & #personSvgCircle {
        color: var(--color-primary-light);
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 2rem 4rem;
        align-items: center;

        @include respond(smallest) {
            grid-template-columns: 1fr;
            place-items: center;
        }

        & ul {
            list-style: none;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        & p {
            line-height: 100%;
            margin-bottom: 0;
            font-size: $fs-medium;
        }

        & svg {
            height: 3rem;
        }
    }

    & h2, &__icons {
        grid-column: 1 / -1;
    }

    &__icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        & a {
            color: var(--color-card-icon);
        }
        
        & svg {
            height: 6rem;
        }
    }

    &__qr img {
        height: 15rem;
    }
}