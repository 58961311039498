.modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(10px);
    z-index: 100;
    display: grid;
    grid-template-columns: [full-start] 1fr $content-padding [content-start] minmax(auto, $content-width) [content-end] $content-padding 1fr [full-end];
    place-items: center;

    &__container {
        position: relative;
        grid-column: content;
        background-color: white;
        color: #222;
        padding: 5rem;
        border-radius: 1rem;
        box-shadow: 0 0 3rem var(--color-shadow);

        @include respond(phone) {
            padding: 2rem;
        }
    }

    &__close {
        position: absolute;
        top: 1.2rem;
        right: 2rem;
        font-size: 4rem;
        line-height: 4rem;
        cursor: pointer;

        @include respond(phone) {
            top: .8rem;
            right: 1rem;
        }

        &:hover {
            color: #888;
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row wrap;
        gap: 3rem;
        margin-top: 2rem;
        
        & .btn {
            min-width: 30rem;
        }
    }

    &__links {
        margin-top: 3rem;
    }
}

.consent {

    & .modal__close {
        cursor: not-allowed;
        color: #888;
    }
}
