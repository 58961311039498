.btn {
    font-family: inherit;
    font-size: $fs-regular;
    padding: .8rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: $border-radius-small;
    box-shadow: .8rem .8rem 0 var(--color-shadow);
    display: inline-block;
    transition: all .2s;
    text-decoration: none;

    &:hover {
        transform: translate(-.2rem, -.2rem);
        box-shadow: 1.2rem 1.2rem 0 var(--color-shadow);
    }
    &:active {
        transform: translate(-.1rem, -.1rem);
        box-shadow: 1rem 1rem 0 var(--color-shadow);
    }

    &--primary {
        background-color: var(--color-primary);
        color: #FFF;
    }
    &--primary-light {
        background-color: var(--color-primary-light);
        color: #FFF;
    }
    &--secondary {
        background-color: var(--color-secondary);
        color: #FFF;
    }
    &--tertiary {
        background-color: var(--color-tertiary);
        color: #FFF;
    }

    &--wide {
        width: 40rem;
        max-width: 100%;
        text-align: center;
        padding: 1.4rem 1.5rem;
        font-size: $fs-regular;
        font-weight: 600;
    }

    &--scroll {
        position: fixed;
        z-index: 50;
        border-radius: 50%;
        bottom: 10rem;
        right: 5rem;
        background-color:  var(--color-scrollbtn);
        box-shadow: .5rem .5rem 0 var(--color-shadow);
        color: #FFF;

        &:hover {
            box-shadow: .9rem .9rem 0 var(--color-shadow);
        }
        &:active {
            box-shadow: .7rem .7rem 0 var(--color-shadow);
        }

        & svg {
            height: 3rem;
            width: 3rem;
        }
    }
}

.link {
    background-color: transparent;
    border: none;
    color: var(--color-link);
    // font-size: $fs-medium;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    padding-bottom: .3rem;
    margin-top: .4rem;
    font-size: $fs-regular;
    font-family: inherit;
    cursor: pointer;
    
    & span {
        display: inline-block;
        width: .5rem;
        transition: all .2s;
    }

    &:hover span {
        width: 1rem;
    }
}