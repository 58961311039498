// SIZES
$fs-big: 3rem;
$fs-medium: 2rem;
$fs-regular: 1.6rem;

$content-width: 1200px;
$content-padding: 4rem;
$content-padding-small: 2rem;

$border-radius: 3px;
$border-radius-small: 2px;

// COLORS
$color-primary: #5F379A;
$color-primary-dark: #40195E;
$color-primary-darkest: #1A0E3B;
$color-primary-light: #9C70DC;
$color-primary-lighter: #B98EFF;
$color-primary-lightest: #CCB2F2;
$color-primary-offwhite: #E3D6FF;

$color-secondary: #F03240;
$color-tertiary: #ED8E34;

$shadow: 1rem 1rem 0px var(--color-shadow);
$shadow-big: 1.2rem 1.2rem 0px .2rem var(--color-shadow);


:root {
    --color-primary: #5F379A;
    --color-primary-dark: #40195E;
    --color-primary-dark-transparent: #580d9180;
    --color-primary-darkest: #1A0E3B;
    --color-primary-light: #9C70DC;
    --color-primary-light-transparent: #9153ee83;
    --color-primary-lighter: #B98EFF;
    --color-primary-lightest: #CCB2F2;
    --color-primary-offwhite: #E3D6FF;

    --gradient: linear-gradient(to bottom right, var(--color-primary), var(--color-primary-dark));
    --gradient-medium: linear-gradient(to bottom right, var(--color-primary-lighter), var(--color-primary-dark));
    --gradient-light: linear-gradient(to bottom right, var(--color-primary-lightest), var(--color-primary-lighter));

    --color-secondary: #ec4853;
    --color-tertiary: #ED8E34;

    --color-shadow: rgba(2, 0, 3, 0.3);

    --color-scrollbar-track: var(--color-primary-darkest);
    --color-scrollbar-thumb: var(--color-primary-light);
    --color-scrollbar-thumb-hover: var(--color-primary-lighter);

    --color-bg: var(--color-primary-darkest);
    --color-text: #FFF;
    --color-text-variant: var(--color-primary-lightest);
    --color-text-hover: var(--color-primary-lightest);

    --color-selection-bg: var(--color-primary-lightest);
    --color-selection-text: var(--color-primary-dark);

    --color-link: var(--color-primary-lightest);

    --color-header: var(--gradient-medium);

    --color-theme-picker: var(--color-primary-darkest);
    --color-theme-picker-text: #FFF;
    --color-theme-picker-indicator: var(--color-primary-light-transparent);

    --color-footer-bg: #000;

    --color-speach: var(--gradient);
    --color-speach2: var(--color-primary);
    --color-skill: var(--gradient);
    --color-logos: var(--color-primary);

    --color-card: var(--gradient);
    --color-card-text: #FFF;
    --color-card-icon: var(--color-primary-lighter);

    --color-scrollbtn: var(--color-primary);

    --color-input: var(--gradient);
    --color-input-text: #FFF;
    --color-placeholder: var(--color-primary-lighter);

    --color-publication: var(--gradient);
    --color-resume: var(--gradient);
    --color-project: var(--gradient);
    --color-project-hover: var(--color-primary-dark-transparent);
}

:root:has(#light:checked), .light {

    --gradient: linear-gradient(to bottom right, #FFF, var(--color-primary-offwhite));

    --color-shadow: rgba(59, 14, 94, 0.3);

    --color-scrollbar-track: var(--color-primary-lightest);
    --color-scrollbar-thumb: var(--color-primary);
    --color-scrollbar-thumb-hover: var(--color-primary-dark);

    --color-bg: var(--color-primary-offwhite);
    --color-text: var(--color-primary-darkest);
    --color-text-variant: var(--color-primary-dark);
    --color-text-hover: var(--color-primary);

    --color-link: var(--color-primary);

    --color-header: var(--gradient-light);

    --color-theme-picker: #FFF;
    --color-theme-picker-text: var(--color-primary-darkest);
    --color-theme-picker-indicator: var(--color-primary-dark-transparent);

    --color-footer-bg: var(--color-primary-lighter);

    --color-speach2: #FFF;
    // --color-skill: #FFF;
    --color-logos: var(--color-primary-light);

    --color-card: linear-gradient(to bottom right, var(--color-primary), var(--color-primary-dark));

    // --color-card: #FFF;
    // --color-card-icon: var(--color-primary-lighter);

    --color-input: #FFF;
    --color-input-text: var(--color-primary-darkest);

    // --color-publication: #FFF;
    // --color-resume: #FFF;
    // --color-project: #FFF;
    --color-project-hover: var(--color-primary-light-transparent);
}