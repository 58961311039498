.u-margin-b-none {
    margin-bottom: 0;
}
.u-margin-b {
    margin-bottom: 2rem;
}
.u-margin-b-big {
    margin-bottom: 5rem;
}

.u-flex-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: 3rem;
    align-items: center;
    justify-content: center;
}

.u-text-center {
    text-align: center;
}
.u-text-left {
    text-align: left;
}