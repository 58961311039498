body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-size: $fs-regular;
}

p {
    line-height: 150%;
    margin-bottom: 1.5rem;;
}

h1 {
    text-align: center;
    font-weight: 500;
    font-size: $fs-big;
}